import prize from "../assets/svg/skills/prize.svg";
import certificate from "../assets/svg/skills/certificate.svg";
import bursary from "../assets/svg/skills/burs.svg";
import news from "../assets/svg/skills/news.svg";
import youtube from "../assets/svg/skills/youtube.svg";

export const skillsImage = (skill) => {
  const skillID = skill.toLowerCase();
  switch (skillID) {
    case "prices":
      return prize;
    case "certificates":
      return certificate;
    case "scholarships":
      return bursary;
    case "news":
      return news;
    case "youtube":
      return youtube;
    default:
      break;
  }
};
