import React, { useContext } from "react";

import "./About.css";
import { ThemeContext } from "../../contexts/ThemeContext";
import arcan from "../../assets/jpg/arcan2.jpeg";
import { useTranslation } from "react-i18next";

function About() {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  return (
    <div
      className="about"
      id="about"
      style={{ backgroundColor: theme.secondary }}
    >
      <div className="line-styling">
        <div
          className="style-circle"
          style={{ backgroundColor: theme.primary }}
        ></div>
        <div
          className="style-circle"
          style={{ backgroundColor: theme.primary }}
        ></div>
        <div
          className="style-line"
          style={{ backgroundColor: theme.primary }}
        ></div>
      </div>
      <div className="about-body">
        <div className="about-description">
          <h2 style={{ color: theme.primary }}>{t("title_long_bio")}</h2>
          <p style={{ color: theme.tertiary80 }}>
            {/* {t("long_bio_first")}
            <br /> */}
            {/* <br /> */}
            {t("long_bio_second")}
          </p>
        </div>
        <div className="about-img">
          <img src={arcan} alt="" />
        </div>
      </div>
    </div>
  );
}

export default About;
