import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeContext } from "../../contexts/ThemeContext";
import { AiFillTrophy } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import "./Achievement.css";
import { Modal, Backdrop } from "@material-ui/core";
function AchievementCard({
  id,
  title,
  details,
  date,
  month,
  year,
  field,
  image,
}) {
  const { t } = useTranslation();

  const { theme } = useContext(ThemeContext);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const useStyles = makeStyles((t) => ({
    achievementCard: {
      backgroundColor: theme.primary30,
      "&:hover": {
        backgroundColor: theme.primary50,
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        backgroundcolor: "red",
      },
    },
    img: {
      outline: "none",
    },
  }));

  const classes = useStyles();
  return (
    <Fade bottom>
      <div
        key={id}
        className={`achievement-card ${classes.achievementCard}`}
        onClick={handleOpen}
      >
        <div className="achievecard-content">
          <div className="achievecard-details1">
            <h2 style={{ color: theme.tertiary }}>{t(title)}</h2>
            <p style={{ color: theme.tertiary80 }}>{t(details)}</p>
          </div>
          <div
            className="achievecard-details2"
            style={{ color: theme.primary }}
          >
            <h5>{date + " " + t(month) + " " + year}</h5>
            <div className="achievecard-field">
              <AiFillTrophy />
              <h5>{t(field)}</h5>
            </div>
          </div>
        </div>
        <div className="achievecard-imgcontainer">
          <img src={image} alt={title} />
        </div>
      </div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} timeout={500} className={classes.img}>
          <img
            src={image}
            alt={title}
            style={{ maxHeight: "90%", maxWidth: "90%" }}
          />
        </Fade>
      </Modal>
    </Fade>
  );
}

export default AchievementCard;
