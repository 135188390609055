import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "react-reveal/Fade";

import { ThemeContext } from "../../contexts/ThemeContext";

import eduImgWhite from "../../assets/svg/education/eduImgWhite.svg";
import eduImgBlack from "../../assets/svg/education/eduImgBlack.svg";
import { useTranslation } from "react-i18next";

import "./Scholarship.css";

function ScholarshipCard({ id, institution, course, startYear, endYear }) {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const useStyles = makeStyles((t) => ({
    educationCard: {
      backgroundColor: theme.primary30,
      "&:hover": {
        backgroundColor: theme.primary50,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Fade bottom>
      <div key={id} className={`education-card ${classes.educationCard}`}>
        <div className="educard-img" style={{ backgroundColor: theme.primary }}>
          <img
            src={theme.type === "light" ? eduImgBlack : eduImgWhite}
            alt=""
          />
        </div>
        <div className="education-details">
          <h6 style={{ color: theme.primary }}>
            {startYear}-{endYear.length !== 4 ? t(endYear) : endYear}
          </h6>
          <h4 style={{ color: theme.tertiary }}>{t(institution)}</h4>
          <h5 style={{ color: theme.tertiary80 }}>{t(course)}</h5>
        </div>
      </div>
    </Fade>
  );
}

export default ScholarshipCard;
