export const projectsData = [
  {
    id: 1,
    projectName: "yt_baslik_1",
    projectDesc: "yt_detay_1",
    tags: "yt_tag_1",
    demo: "https://youtu.be/HijX9V2QgAE",
    image: "https://i.ytimg.com/vi/HijX9V2QgAE/maxresdefault.jpg",
  },
  {
    id: 2,
    projectName: "yt_baslik_2",
    projectDesc: "yt_detay_2",
    tags: "yt_tag_2",
    demo: "https://youtu.be/hJ_x1di0iR0?t=3830",
    image:
      "https://www.enkasanat.org/wp-content/uploads/2022/01/56-1024x546.jpg",
  },
  {
    id: 3,
    projectName: "yt_baslik_3",
    projectDesc: "yt_detay_3",
    tags: "yt_tag_3",
    demo: "https://youtu.be/-Ig-wZ4l-j0?t=126",
    image:
      "https://images.squarespace-cdn.com/content/v1/60e479ac1a9d741db32d1b0d/dfdf0b5e-c9de-4426-9196-151c3ab2d682/Screen+Shot+2021-09-08+at+2.28.06+PM.png",
  },
  {
    id: 4,
    projectName: "yt_baslik_4",
    projectDesc: "yt_detay_4",
    tags: "yt_tag_4",
    demo: "https://www.youtube.com/watch?v=x0-hGSzo4Cg",
    image:
      "https://i.ytimg.com/vi/x0-hGSzo4Cg/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCci5E0Aq6U6SzvZJONGFthYSDbNg",
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
