export const certificateData = [
  {
    id: 1,
    title: "certificate_prize_1",
    details: "certificate_place_1",
    date: "29",
    month: "May",
    year: "2018",
    image: "./assets/certificates/cert_1.png",
    field: "category_1",
  },
  {
    id: 2,
    title: "certificate_prize_2",
    details: "certificate_place_2",
    date: "29",
    month: "May",
    year: "2019",
    image: "./assets/certificates/cert_2.png",
    field: "category_1",
  },
  {
    id: 3,
    title: "certificate_prize_3",
    details: "certificate_place_3",
    date: "23-26",
    month: "Jan",
    year: "2020",
    image: "./assets/certificates/cert_3.png",
    field: "category_1",
  },
  {
    id: 4,
    title: "certificate_prize_4",
    details: "certificate_place_4",
    date: "4-21",
    month: "Aug",
    year: "2022",
    image: "./assets/certificates/cert_4.png",
    field: "category_1",
  },
];
