import React, { useContext, useState } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
import Fade from "react-reveal/Fade";
import {
  IoMenuSharp,
  IoTrophy,
  IoSchoolSharp,
  IoNewspaperSharp,
  IoLogoYoutube,
} from "react-icons/io5";
import { TbCertificate } from "react-icons/tb";

import { MdPhone } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";

import "./Navbar.css";
import { headerData } from "../../data/headerData";
import { ThemeContext } from "../../contexts/ThemeContext";

import i18next from "i18next";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";

function Navbar() {
  const { theme, setHandleDrawer } = useContext(ThemeContext);
  const currentLanguageCode = cookies.get("i18next") || "tr";
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    setOpen(true);
    setHandleDrawer();
  };
  const changeLanguage = (language) => {
    i18next.changeLanguage(language);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setHandleDrawer();
  };

  const useStyles = makeStyles((t) => ({
    navMenu: {
      fontSize: "2.5rem",
      color: theme.tertiary,
      cursor: "pointer",
      transform: "translateY(-10px)",
      transition: "color 0.3s",
      top: "30px",
      right: "20px",
      "&:hover": {
        color: theme.primary,
      },
      [t.breakpoints.down("sm")]: {
        fontSize: "2.5rem",
      },
      [t.breakpoints.down("xs")]: {
        fontSize: "2rem",
      },
    },
    navTR: {
      fontSize: "2.5rem",
      color: theme.tertiary,
      cursor: "pointer",
      transform: "translateY(-10px)",
      transition: "color 0.3s",
      position: "absolute",
      "&:hover": {
        color: theme.primary,
      },
      [t.breakpoints.down("sm")]: {
        fontSize: "2.5rem",
      },
      [t.breakpoints.down("xs")]: {
        fontSize: "2rem",
      },
      opacity: currentLanguageCode === "tr" ? 0.5 : 1,
    },
    navEN: {
      fontSize: "2.5rem",
      color: theme.tertiary,
      cursor: "pointer",
      marginTop: "30px",
      transition: "color 0.3s",
      position: "absolute",

      "&:hover": {
        color: theme.primary,
      },
      [t.breakpoints.down("sm")]: {
        fontSize: "2.5rem",
      },
      [t.breakpoints.down("xs")]: {
        fontSize: "2rem",
      },
      opacity: currentLanguageCode === "en" ? 0.5 : 1,
    },
    navGER: {
      fontSize: "2.5rem",
      color: theme.tertiary,
      cursor: "pointer",
      transition: "color 0.3s",
      position: "absolute",
      marginTop: "70px",
      "&:hover": {
        color: theme.primary,
      },
      [t.breakpoints.down("sm")]: {
        fontSize: "2.5rem",
      },
      [t.breakpoints.down("xs")]: {
        fontSize: "2rem",
      },
      opacity: currentLanguageCode === "ger" ? 0.5 : 1,
    },
    MuiDrawer: {
      padding: "0em 1.8em",
      width: "14em",
      fontFamily: " var(--primaryFont)",
      fontStyle: " normal",
      fontWeight: " normal",
      fontSize: " 24px",
      background: theme.secondary,
      overflow: "hidden",
      borderTopRightRadius: "40px",
      borderBottomRightRadius: "40px",
      [t.breakpoints.down("sm")]: {
        width: "12em",
      },
    },
    closebtnIcon: {
      fontSize: "2rem",
      fontWeight: "bold",
      cursor: "pointer",
      color: theme.primary,
      position: "absolute",
      right: 40,
      top: 40,
      transition: "color 0.2s",
      "&:hover": {
        color: theme.tertiary,
      },
      [t.breakpoints.down("sm")]: {
        right: 20,
        top: 20,
      },
    },
    drawerItem: {
      margin: "2rem auto",
      borderRadius: "78.8418px",
      background: theme.secondary,
      color: theme.primary,
      width: "85%",
      height: "60px",
      display: "flex",
      alignItems: "center",

      columnGap: "15px",
      paddingLeft: "15px",
      padding: "0 5px",
      boxSizing: "border-box",
      border: "2px solid",
      borderColor: theme.primary,
      transition: "background-color 0.2s, color 0.2s",
      "&:hover": {
        background: theme.primary,
        color: theme.secondary,
      },
      [t.breakpoints.down("sm")]: {
        width: "100%",
        height: "55px",
      },
    },
    drawerLinks: {
      fontFamily: "var(--primaryFont)",
      width: "50%",
      fontSize: "1.3rem",
      fontWeight: 600,
      [t.breakpoints.down("sm")]: {
        fontSize: "1.125rem",
      },
    },
    drawerIcon: {
      fontSize: "1.6rem",
      [t.breakpoints.down("sm")]: {
        fontSize: "1.385rem",
      },
    },
  }));

  const classes = useStyles();

  const shortname = (name) => {
    if (name.length > 12) {
      return name.split(" ")[0];
    } else {
      return name;
    }
  };

  return (
    <div className="navbar">
      <div className="navbar--container">
        <h1 style={{ color: theme.secondary }}>{shortname(headerData.name)}</h1>
        <div>
          <IoMenuSharp
            className={classes.navMenu}
            onClick={handleDrawerOpen}
            aria-label="Menu"
            id="navicon"
          />

          <div
            onClick={() => changeLanguage("tr")}
            aria-label="Menu"
            id="navicon"
            className={classes.navTR}
          >
            🇹🇷
          </div>

          {/* <div
            onClick={() => changeLanguage("ger")}
            aria-label="Menu"
            id="navicon"
            className={classes.navGER}
          >
            🇩🇪
          </div> */}

          <div
            onClick={() => changeLanguage("en")}
            aria-label="Menu"
            id="navicon"
            className={classes.navEN}
          >
            🇬🇧
          </div>
        </div>
      </div>
      <Drawer
        variant="temporary"
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleDrawerClose();
          } else if (reason !== "escapeKeyDown") {
            handleDrawerClose();
          }
        }}
        anchor="left"
        open={open}
        classes={{ paper: classes.MuiDrawer }}
        className="drawer"
        disableScrollLock={true}
      >
        <div className="div-closebtn">
          <CloseIcon
            onClick={handleDrawerClose}
            onKeyDown={(e) => {
              if (e.key === " " || e.key === "Enter") {
                e.preventDefault();
                handleDrawerClose();
              }
            }}
            className={classes.closebtnIcon}
            role="button"
            tabIndex="0"
            aria-label="Close"
          />
        </div>
        <br />

        <div onClick={handleDrawerClose}>
          <div className="navLink--container">
            <Fade left>
              <NavLink to="/#about" smooth={true} spy="true" duration={2000}>
                <div className={classes.drawerItem}>
                  <FaUser className={classes.drawerIcon} />
                  <span className={classes.drawerLinks}>{t("whoami")}</span>
                </div>
              </NavLink>
            </Fade>

            <Fade left>
              <NavLink to="/#prize" smooth={true} spy="true" duration={2000}>
                <div className={classes.drawerItem}>
                  <IoTrophy className={classes.drawerIcon} />
                  <span className={classes.drawerLinks}>{t("prices")}</span>
                </div>
              </NavLink>
            </Fade>

            <Fade left>
              <NavLink
                to="/#scholarship"
                smooth={true}
                spy="true"
                duration={2000}
              >
                <div className={classes.drawerItem}>
                  <IoSchoolSharp className={classes.drawerIcon} />
                  <span className={classes.drawerLinks}>
                    {t("scholarships")}
                  </span>
                </div>
              </NavLink>
            </Fade>
            <Fade left>
              <NavLink
                to="/#certificate"
                smooth={true}
                spy="true"
                duration={2000}
              >
                <div className={classes.drawerItem}>
                  <TbCertificate className={classes.drawerIcon} />
                  <span className={classes.drawerLinks}>
                    {t("certificates")}
                  </span>
                </div>
              </NavLink>
            </Fade>
            <Fade left>
              <NavLink to="/#news" smooth={true} spy="true" duration={2000}>
                <div className={classes.drawerItem}>
                  <IoNewspaperSharp className={classes.drawerIcon} />
                  <span className={classes.drawerLinks}>{t("news")}</span>
                </div>
              </NavLink>
            </Fade>
            <Fade left>
              <NavLink to="/#youtube" smooth={true} spy="true" duration={2000}>
                <div className={classes.drawerItem}>
                  <IoLogoYoutube className={classes.drawerIcon} />
                  <span className={classes.drawerLinks}>{t("youtube")}</span>
                </div>
              </NavLink>
            </Fade>

            <Fade left>
              <NavLink to="/#contacts" smooth={true} spy="true" duration={2000}>
                <div className={classes.drawerItem}>
                  <MdPhone className={classes.drawerIcon} />
                  <span className={classes.drawerLinks}>{t("contact")}</span>
                </div>
              </NavLink>
            </Fade>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Navbar;
