export const blogData = [
  {
    id: 1,
    title: "ARCAN İSENKUL’UN BESTECİLİK ALANINDAKİ BAŞARISI",
    description: "New York'da düzenlenen MATA Jr. 2021 (Music at the Anthology) festivalinde Arcan İsenkul’un eseri finale kalan altı eser arasına girmiş, böylece festivalde yer alacak altı besteciden biri olarak seçilmiştir. \"Heves\" adlı eseri, Bergamot Quartet tarafından 17 Ekim 2021 tarihinde Brooklyn/New York'da seslendirilmiştir.",
    year: "2021",
    month: "Sep",
    image: "./assets/blog/blog_1.png",
    url: "http://mhl.bilkent.edu.tr/haberler/arcan-isenkulun-bestecilik-alanindaki-basarisi"
  },
  {
    id: 2,
    title: "ARCAN İSENKUL’UN ODA MÜZİĞİ GRUBUBUNA AMERİKA’DA BİRİNCİLİK ÖDÜLÜ ",
    description:
      "Amerika’da çevrimiçi olarak düzenlenen “King’s Peak” Uluslararası Müzik Yarışması Oda Müziği kategorisinde Bilkent Üniversitesi Müzik Hazırlık Lisesi 11. sınıf öğrencilerinden Ceyda Özeser (keman), Arda Büyükgüral (viyolonsel), Alp Birsel (piyano) ve 10. sınıf öğrencisi Arcan İsenkul’un (viyola) yer aldığı oda müziği grubu birincilik ödülüne layık görüldü.",
      year: "2021",
      month: "Apr",
      image: "./assets/blog/blog_2.png",
      url: "http://mhl.bilkent.edu.tr/haberler/oda-muzigi-grubumuza-amerikada-birincilik-odulu "  },
  {
    id: 3,
    title: 'ARCAN İSENKUL “KING’S PEAK” ULUSLARARASI MÜZİK YARIŞMASINDA BİRİNCİ OLDU ',
    description: 'Amerika’da çevrimiçi olarak düzenlenen “King’s Peak” Uluslararası Müzik Yarışmasında Arcan İsenkul birincilik ödülünü aldı.',
    year: "2021",
    month: "Mar",
    image: "./assets/blog/blog_3.png",
    url: "http://mhl.bilkent.edu.tr/haberler/arcan-isenkul-ve-pinar-ozoral-kings-peak-uluslararasi-muzik-yarismasinda-birinci-ve-ikinci-oldular "}, 
  {
    id: 4,
    title: 'ARCAN İSENKUL’A ÜÇÜNCÜLÜK ÖDÜLÜ',
    description: "30 Ocak 2021 tarihinde “Online Klasik Müzik Akademisi” tarafından düzenlenen keman-viyola-viyolonsel yarışmasında Bilkent Üniversitesi Müzik Hazırlık Lisesi viyola sanat dalı öğrencisi Arcan İsenkul 15-25 yaş kategorisinde üçüncülük ödülüne layık görüldü.",
    year: "2021",
    month: "Jan",
    image: "./assets/blog/blog_4.png",
    url: "http://mhl.bilkent.edu.tr/haberler/arcan-isenkuldan-ucunculuk"}, 
  {
    id: 5,
    title: "ARCAN İSENKUL’A İTALYA’DAN İKİ ÖDÜL ",
    description: 'Arcan İsenkul, 6-8 Mayıs 2019’da İtalya’da düzenlenen ve dünyadan pek çok müzisyenin katıldığı “29th Young Musician International Competition ‘Città di Barletta’ Müzik Yarışması”nda yaylı çalgılar dalında birincilik ödülünün yanı sıra Artistik yönetmeni Francesco MONOPOLI Özel Ödülü’ne de layık görülmüştür. 8 Mayıs 2019’da gerçekleşen Gala Konseri’nde ödülünü almaya davet edilmiştir.',
    year: "2019",
    month: "May",
    image: "./assets/blog/blog_5.png",
    url: "http://mhl.bilkent.edu.tr/haberler/arcan-isenkula-italyadan-iki-odul "
}, 
{
  id: 6,
  title: 'ARCAN İSENKUL NEW YORK’DA BİRİNCİ OLDU',
  description: "New York’da düzenlenen Luzerne Music Center Konçerto Yarışmasında Arcan İsenkul birincilik ödülü aldı.",
  year: "2018",
  month: "July",
  image: "./assets/blog/blog_6.png",
  url: " http://mhl.bilkent.edu.tr/haberler/arcan-isenkul-new-yorkda-birinci-oldu"
},
{
  id: 7,
  title: 'ARCAN İSENKUL İTALYA’DA BİRİNCİ OLDU',
  description: "İtalya’nın Verona şehrine bağlı Legnago’da 5-8 Nisan 2018 tarihleri arasında düzenlenen IX International Young Musicians Competition “Antonio Salieri” 2018 Ahmed Adnan Saygun Başarı Bursuyla katılan Bilkent Üniversitesi Müzik ve Sahne Sanatları Fakültesi Müzik Hazırlık Lisesi Viyola Sanat Dalı 7. sınıf öğrencisi Arcan İsenkul “Antonio Salieri” Birincilik ödülünü kazandı.",
  year: "2018",
  month: "Apr",
  image: "./assets/blog/blog_7.png",
  url: " http://mhl.bilkent.edu.tr/haberler/arcan-isenkul-italyada-birinci-oldu"
},
{
  id: 8,
  title: 'ARCAN İSENKUL ROMA’DA ÖDÜL KAZANDI',
  description: " İtalya’nın Roma kentinde düzenlenen \"Rome Grand Prize Virtuoso 2017\" Uluslararası Müzik Yarışmasında Bilkent Üniversitesi Müzik Hazırlık Lisesi Viyola Sanat Dalı 7. sınıf öğrencisi Arcan İsenkul yaylı çalgılar dalında ikincilik ödülü kazandı ve ünlü \"Auditorium Parco della Musica\" konser salonunda 19 Aralık’ta düzenlenen törenle ödülünü aldı. Ödül törenine hocası Ece Akyol ile birlikte katıldı.",
  year: "2017",
  month: "Dec",
  image: "./assets/blog/bloASDFLJHKASDg_8.png",
  url: " https://www.grandprizevirtuosointernationalmusiccompetition.com/newpage13269bdb"
},

];

// Do not remove any fields.
// Leave it blank instead as shown below.

/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/
