import React, { useContext } from "react";

import "./Achievement.css";
import { ThemeContext } from "../../contexts/ThemeContext";
import { achievementData } from "../../data/achievementData";
import AchievementCard from "./AchievementCard";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
function Achievement() {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <>
      {achievementData.achievements.length > 0 && (
        <div
          className="prize"
          id="prize"
          style={{ backgroundColor: theme.secondary }}
        >
          <div className="achievement-body">
            <h1 style={{ color: theme.primary, textAlign: "center" }}>
              {t("prices")}
            </h1>
            <div className="achievement-cards">
              <Grid container spacing={2}>
                {achievementData.achievements.map((achieve) => (
                  <Grid key={achieve.id + "-grid"} item xs={12} sm={12} md={6}>
                    <AchievementCard
                      key={achieve.id}
                      id={achieve.id}
                      title={achieve.title}
                      details={achieve.details}
                      date={achieve.date}
                      month={achieve.month}
                      year={achieve.year}
                      field={achieve.field}
                      image={achieve.image}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Achievement;
