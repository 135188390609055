import React, { useContext } from "react";
import Marquee from "react-fast-marquee";

import "./Skills.css";
import { NavHashLink as NavLink } from "react-router-hash-link";

import { ThemeContext } from "../../contexts/ThemeContext";
import { skillsImage } from "../../utils/skillsImage";
import { useTranslation } from "react-i18next";

function Skills() {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  const skillBoxStyle = {
    backgroundColor: theme.secondary,
    boxShadow: `0px 0px 30px ${theme.primary30}`,
  };
  return (
    <div
      id="skills"
      className="skills"
      style={{ backgroundColor: theme.secondary }}
    >
      <div className="skillsHeader">
        <h2 style={{ color: theme.primary }}>{t("content")}</h2>
      </div>
      <div className="skillsContainer">
        <div className="skill--scroll">
          <Marquee
            gradient={false}
            speed={80}
            pauseOnHover={true}
            pauseOnClick={true}
            delay={0}
            play={true}
            direction="left"
          >
            <NavLink to="/#prize" smooth key="1" duration={2000}>
              <div className="skill--box" style={skillBoxStyle}>
                <img src={skillsImage("prices")} alt="prices" />
                <h3 style={{ color: theme.tertiary }}>{t("prices")}</h3>
              </div>
            </NavLink>
            <NavLink
              to="/#scholarship"
              smooth={true}
              spy="true"
              key="2"
              duration={2000}
            >
              <div className="skill--box" style={skillBoxStyle}>
                <img src={skillsImage("scholarships")} alt="scholarships" />
                <h3 style={{ color: theme.tertiary }}>{t("scholarships")}</h3>
              </div>
            </NavLink>
            <NavLink to="/#certificate" smooth key="3" duration={2000}>
              <div className="skill--box" style={skillBoxStyle}>
                <img src={skillsImage("certificates")} alt="certificates" />
                <h3 style={{ color: theme.tertiary }}>{t("certificates")}</h3>
              </div>
            </NavLink>
            <NavLink
              to="/#news"
              smooth={true}
              spy="true"
              key="4"
              duration={2000}
            >
              <div className="skill--box" style={skillBoxStyle}>
                <img src={skillsImage("news")} alt="news" />
                <h3 style={{ color: theme.tertiary }}>{t("news")}</h3>
              </div>
            </NavLink>
            <NavLink to="/#youtube" smooth spy="true" key="5" duration={2000}>
              <div className="skill--box" style={skillBoxStyle}>
                <img src={skillsImage("youtube")} alt="youtube" />
                <h3 style={{ color: theme.tertiary }}>{t("youtube")}</h3>
              </div>
            </NavLink>
          </Marquee>
        </div>
      </div>
    </div>
  );
}

export default Skills;
