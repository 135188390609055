import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";

import "./Experience.css";

import { certificateData } from "../../data/certificateData";
import { useTranslation } from "react-i18next";
import AchievementCard from "../Achievement/AchievementCard";

function Certificate() {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <div
      className="certificate"
      id="certificate"
      style={{ backgroundColor: theme.secondary }}
    >
      <div className="experience-body">
        <div className="experience-image">
          <img src={theme.expimg} alt="" />
        </div>
        <div className="experience-description">
          <h1 style={{ color: theme.primary }}>{t("certificates")}</h1>
          {certificateData.map((achieve) => (
            <AchievementCard
              key={achieve.id}
              id={achieve.id}
              title={achieve.title}
              details={achieve.details}
              date={achieve.date}
              month={achieve.month}
              year={achieve.year}
              field={achieve.field}
              image={achieve.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Certificate;
