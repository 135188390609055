import React, { useContext } from "react";
import "./Footer.css";
import { ThemeContext } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  return (
    <div className="footer" style={{ backgroundColor: theme.secondary }}>
      <p style={{ color: theme.tertiary }}>{t("footer")}</p>
    </div>
  );
}

export default Footer;
