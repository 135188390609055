export const scholarshipData = [
  {
    id: 1,
    institution: "burs_1",
    course: "burs_place_1",
    startYear: "2017",
    endYear: "2017",
  },
  {
    id: 2,
    institution: "burs_2",
    course: "burs_place_2",
    startYear: "2018",
    endYear: "2018",
  },
  {
    id: 3,
    institution: "burs_3",
    course: "burs_place_3",
    startYear: "2018",
    endYear: "2018",
  },
  {
    id: 4,
    institution: "burs_4",
    course: "burs_place_4",
    startYear: "2019",
    endYear: "2019",
  },
  {
    id: 5,
    institution: "burs_5",
    course: "burs_place_5",
    startYear: "2020",
    endYear: "2020",
  },
  {
    id: 6,
    institution: "burs_6",
    course: "burs_place_6",
    startYear: "2022",
    endYear: "2022",
  },
];
