export const achievementData = {
  bio: "The illiterate of the 21st century will not be those who cannot read and write, but those who cannot learn, unlearn, and relearn. - Alvin Toffler",
  achievements: [
    {
      id: 1,
      title: "prize_10",
      details: "place_10",
      date: "",
      month: "Aug",
      year: "2021",
      field: "category_2",
      image: "./assets/prizes/prize_10.png",
    },
    {
      id: 2,
      title: "prize_3",
      details: "place_3",
      date: "15",
      month: "Aug",
      year: "2021",
      field: "category_2",
      image: "./assets/prizes/prize_3.png",
    },
    {
      id: 3,
      title: "prize_2",
      details: "place_2",
      date: "6",
      month: "Apr",
      year: "2021",
      field: "category_2",
      image: "./assets/prizes/prize_2.png",
    },
    {
      id: 4,
      title: "prize_5",
      details: "place_5",
      date: "31",
      month: "Mar",
      year: "2021",
      field: "category_2",
      image: "./assets/prizes/prize_5.png",
    },
    {
      id: 5,
      title: "prize_4",
      details: "place_4",
      date: "30",
      month: "Jan",
      year: "2021",
      field: "category_2",
      image: "./assets/prizes/prize_4.png",
    },

    {
      id: 6,
      title: "prize_7",
      details: "place_7",
      date: "6-12",
      month: "May",
      year: "2019",
      field: "category_2",
      image: "./assets/prizes/prize_7.png",
    },
    {
      id: 7,
      title: "prize_8",
      details: "place_8",
      date: "6-12",
      month: "May",
      year: "2019",
      field: "category_2",
      image: "./assets/prizes/prize_8.png",
    },
    {
      id: 8,
      title: "prize_1",
      details: "place_1",
      date: "14",
      month: "Jul",
      year: "2018",
      field: "category_2",
      image: "./assets/prizes/prize_1.png",
    },
    {
      id: 9,
      title: "prize_9",
      details: "place_9",
      date: "7",
      month: "Apr",
      year: "2018",
      field: "category_2",
      image: "./assets/prizes/prize_9.png",
    },
    {
      id: 10,
      title: "prize_6",
      details: "place_6",
      date: "",
      month: "Dec",
      year: "2017",
      field: "category_2",
      image: "./assets/prizes/prize_6.png",
    },
  ],
};

// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
