import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";

import "./Scholarship.css";
import ScholarshipCard from "./ScholarshipCard";
import { useTranslation } from "react-i18next";

import { scholarshipData } from "../../data/scholarshipData";

function Scholarship() {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <div
      className="scholarship"
      id="scholarship"
      style={{ backgroundColor: theme.secondary }}
    >
      <div className="education-body">
        <div className="education-description">
          <h1 style={{ color: theme.primary }}>{t("scholarships")}</h1>
          {scholarshipData.map((edu) => (
            <ScholarshipCard
              key={edu.id}
              id={edu.id}
              institution={edu.institution}
              course={edu.course}
              startYear={edu.startYear}
              endYear={edu.endYear}
            />
          ))}
        </div>
        <div className="education-image">
          <img src={theme.eduimg} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Scholarship;
