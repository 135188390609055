export const socialsData = {
  github: "",
  facebook: "",
  linkedIn: "",
  instagram: "https://www.instagram.com/arcanisenkul/",
  codepen: "",
  twitter: "",
  reddit: "",
  blogger: "",
  medium: "",
  stackOverflow: "",
  gitlab: "",
  youtube: "",
};
