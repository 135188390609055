import React, { useContext } from "react";

import "./Landing.css";
import { ThemeContext } from "../../contexts/ThemeContext";
import { headerData } from "../../data/headerData";
import { socialsData } from "../../data/socialsData";
import { useTranslation } from "react-i18next";
import arcan from "../../assets/jpg/arcan3.png";
import { FaInstagram } from "react-icons/fa";

function Landing() {
  const { theme, drawerOpen } = useContext(ThemeContext);
  const { t } = useTranslation();
  // const useStyles = makeStyles((t) => ({
  //   resumeBtn: {
  //     color: theme.primary,
  //     borderRadius: "30px",
  //     textTransform: "inherit",
  //     textDecoration: "none",
  //     width: "150px",
  //     fontSize: "1rem",
  //     fontWeight: "500",
  //     height: "50px",
  //     fontFamily: "var(--primaryFont)",
  //     border: `3px solid ${theme.primary}`,
  //     transition: "100ms ease-out",
  //     "&:hover": {
  //       backgroundColor: theme.tertiary,
  //       color: theme.secondary,
  //       border: `3px solid ${theme.tertiary}`,
  //     },
  //     [t.breakpoints.down("sm")]: {
  //       width: "180px",
  //     },
  //   },
  //   contactBtn: {
  //     backgroundColor: theme.primary,
  //     color: theme.secondary,
  //     borderRadius: "30px",
  //     textTransform: "inherit",
  //     textDecoration: "none",
  //     width: "150px",
  //     height: "50px",
  //     fontSize: "1rem",
  //     fontWeight: "500",
  //     fontFamily: "var(--primaryFont)",
  //     border: `3px solid ${theme.primary}`,
  //     transition: "100ms ease-out",
  //     "&:hover": {
  //       backgroundColor: theme.secondary,
  //       color: theme.tertiary,
  //       border: `3px solid ${theme.tertiary}`,
  //     },
  //     [t.breakpoints.down("sm")]: {
  //       display: "none",
  //     },
  //   },
  // }));

  return (
    <div className="landing">
      <div className="landing--container">
        <div
          className="landing--container-left"
          style={{ backgroundColor: theme.primary }}
        >
          <div className="lcl--content">
            {socialsData.instagram && (
              <a href={socialsData.instagram} target="_blank" rel="noreferrer">
                <FaInstagram
                  className="landing--social"
                  style={{ color: theme.secondary }}
                  aria-label="Instagram"
                />
              </a>
            )}
            {/* {socialsData.twitter && (
                            <a
                                href={socialsData.twitter}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <FaTwitter
                                    className='landing--social'
                                    style={{ color: theme.secondary }}
                                    aria-label='Twitter'
                                />
                            </a>
                        )}
                        {socialsData.youtube && (
                            <a
                                href={socialsData.youtube}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <FaYoutube
                                    className='landing--social'
                                    style={{ color: theme.secondary }}
                                    aria-label='YouTube'
                                />
                            </a>
                        )}
                        {socialsData.blogger && (
                            <a
                                href={socialsData.blogger}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <FaBlogger
                                    className='landing--social'
                                    style={{ color: theme.secondary }}
                                    aria-label='Blogger'
                                />
                            </a>
                        )} */}
          </div>
        </div>
        <img
          src={arcan}
          alt=""
          className="landing--img"
          style={{
            opacity: `${drawerOpen ? "0" : "1"}`,
            borderColor: theme.secondary,
          }}
        />
        <div
          className="landing--container-right"
          style={{ backgroundColor: theme.secondary }}
        >
          <div className="lcr--content" style={{ color: theme.tertiary }}>
            <h1 style={{ textAlign: "center" }}>
              {headerData.name.toLocaleUpperCase()}
            </h1>
            <div style={{ fontSize: "2rem", textAlign: "center" }}>
              {t("viola_player")}

              <p
                style={{
                  paddingLeft: "5px",
                  display: "inline",
                  fontSize: "3rem",
                }}
              >
                {" "}
                🎻
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <p>{t("bilkent_uni")}</p>
              <p>{t("bilkent_departman")}</p>
              <p>{t("muzik_lise")}</p>
            </div>

            {/* <p>{t("short_bio")}</p> */}
            {/* <div className="lcr-buttonContainer">
              {headerData.resumePdf && (
                <NavLink to="/#about" smooth={true} spy="true" duration={2000}>
                  <Button className={classes.resumeBtn}>
                    {t("title_long_bio").toLocaleUpperCase()}
                  </Button>
                </NavLink>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
